import {
  Button,
  Collapse,
  Divider,
  Paper,
  TextInput,
  Title,
} from "@mantine/core";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import ResetPassword from "./ResetPassword";

const Login = () => {
  const [opened, setOpen] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    // console.log(e.target.email.value)
    const authentication = getAuth();
    signInWithEmailAndPassword(
      authentication,
      e.target.email.value,
      e.target.password.value
    )
      .then((response) => {
        // navigate('/')
        sessionStorage.setItem(
          "Auth Token",
          response._tokenResponse.refreshToken
        );
      })
      .catch((error) => {
        //   setlogonState({state: 'error', message: error.message})
      });
  };

  return (
    <Paper withBorder p={"md"}>
      <Title mb={"xl"} order={1}>
        Login
      </Title>
      <form onSubmit={handleLogin}>
        <TextInput name="email" label="Email" /> <br />
        <br />
        <TextInput name="password" type="password" label="Password" /> <br />
        <br />
        <Button fullWidth type="submit">
          Submit
        </Button>
      </form>

      <Divider my={"lg"} />

      <Button
        disabled={opened}
        size="xs"
        variant="outline"
        fullWidth
        onClick={() => setOpen((o) => !o)}
      >
        Reset Password
      </Button>

      <Collapse in={opened}>
        <ResetPassword />
      </Collapse>
    </Paper>
  );
};

export default Login;
