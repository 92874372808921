import { Card } from "@mantine/core";
import NewRent from "./NewRent/NewRent";
import RentList from "./RentList";

const Rent = () => {
  return (
    <>
      <Card withBorder>
        {/* <CardContent> */}
        <NewRent />
        {/* </CardContent> */}
      </Card>

      <Card withBorder>
        {/* <CardContent> */}
        <RentList title="Open" status="open" />
        {/* </CardContent> */}
      </Card>

      <Card withBorder>
        {/* <CardContent> */}
        <RentList title="Done" status="done" />
        {/* </CardContent> */}
      </Card>
    </>
  );
};

export default Rent;
