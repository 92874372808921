import { Button, TextInput } from "@mantine/core";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    const authentication = getAuth();
    setLoading(true);
    sendPasswordResetEmail(authentication, e.target.email.value)
      .then(() => {
        alert("Email sent.");
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleReset}>
        <TextInput disabled={loading} name="email" label="Email" /> <br />
        <Button loading={loading} fullWidth type="submit">
          Reset Password
        </Button>
      </form>
    </div>
  );
};

export default ResetPassword;
