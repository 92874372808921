import {
  Autocomplete,
  Badge,
  Button,
  Group,
  Paper,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useState } from "react";
import { addRent, updateRent } from "../../../Firestore/firestore";
import { useForm } from "@mantine/hooks";
import { useSelector } from "react-redux";

const RentFormComponent = (props) => {
  const [loading, setloading] = useState(false);

  let initalListRentItems = [];
  let initalValuesModify = {
    name: "",
    type: "",
    contactDetailsRentPerson: "",
    rentDateRange: [],
    fach: "",
    fachCode: "",
    comments: "",
    status: "open",
  };

  if (props.type === "modify") {
    initalListRentItems = props.data.items;
    initalValuesModify = {
      ...props.data,
      rentDateRange: [
        new Date(props.data.rentDateRange[0].toMillis()),
        new Date(props.data.rentDateRange[1].toMillis()),
      ],
    };
  }

  const [rentItemsArray, setrentItemsArray] = useState([
    ...initalListRentItems,
  ]);
  const [itemsInputValue, setitemsInputValue] = useState();

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const submit = async (data) => {
    setloading(true);
    try {
      const dataObject = {
        ...data,
        items: rentItemsArray,
      };
      if (data.rentDateRange.length !== 2) {
        alert("Please fill out Rent date range");
      } else {
        if (props.type === "create") {
          await addRent({
            ...dataObject,
            createdBy: userEmail,
          });
          setrentItemsArray([]);
          form.reset();
        }
        if (props.type === "modify") {
          await updateRent(props.data.id, {
            ...dataObject,
            modifiedLastBy: userEmail,
          });
        }
      }
    } catch (error) {
    } finally {
      setTimeout(() => {
        setloading(false);
      }, 200);
    }
  };

  const form = useForm({
    initialValues: initalValuesModify,
  });

  const gearListArray = useSelector((state) => state.gear.gearList);
  const userEmail = useSelector((state) => state.auth.email);

  return (
    <form onSubmit={form.onSubmit((values) => submit(values))}>
      <TextInput
        required
        margin="normal"
        label="Name"
        {...form.getInputProps("name")}
      />

      <Paper withBorder p={"xs"} my={"xs"}>
        <Autocomplete
          limit={10}
          label="Items"
          value={itemsInputValue}
          onChange={setitemsInputValue}
          placeholder="Pick rent items"
          data={gearListArray.map(function (obj) {
            return `${obj.name} (${obj.category})`;
          })}
        />
        <Button
          fullWidth
          disabled={itemsInputValue === undefined || itemsInputValue === ""}
          mt={"xs"}
          onClick={() => {
            console.log(itemsInputValue);
            setrentItemsArray([...rentItemsArray, itemsInputValue]);
            setitemsInputValue("");
          }}
        >
          Add item
        </Button>

        <Group spacing="xs" mt={"xs"}>
          {rentItemsArray.length === 0 && <Badge>No items selected</Badge>}
          {rentItemsArray.map((item, index) => {
            return (
              <Badge
                key={index}
                onClick={(e) => {
                  // console.log(e.target.innerHTML);
                  const tmpArr = [...rentItemsArray];
                  removeItemAll(tmpArr, e.target.innerHTML);
                  setrentItemsArray(tmpArr);
                }}
                variant="outline"
                sx={{ paddingRight: 10 }}
                // rightSection={<X />}
              >
                {item}
              </Badge>
            );
          })}
        </Group>
      </Paper>

      <Select
        label="Type"
        placeholder="Pick one"
        data={[
          { value: "sharely", label: "Sharely" },
          { value: "drehVisure", label: "Dreh von Visure" },
          {
            value: "mietePrivat",
            label: "Miete private (zb. Janis nimmt dR6 id ferie 🏝 mit)",
          },
          {
            value: "mietePrivatFirma",
            label:
              "Miete private (zb. Claude mietet pavotubes über Janis direkt (nicht sharely)",
          },
        ]}
        {...form.getInputProps("type")}
      />

      <Textarea
        autosize
        maxRows={5}
        label="Kontaktangaben Mieter"
        {...form.getInputProps("contactDetailsRentPerson")}
      />

      <DateRangePicker
        required
        label="Rent date range"
        placeholder="Pick date range"
        allowSingleDateInRange
        {...form.getInputProps("rentDateRange")}
      />

      <Select
        label="Fach (in Box)"
        placeholder="Pick one"
        data={[
          { value: "A", label: "A" },
          { value: "B", label: "B" },
          { value: "C", label: "C" },
        ]}
        {...form.getInputProps("fach")}
      />

      <TextInput
        placeholder="999"
        type="number"
        label="Code (Schloss an Fach)"
        {...form.getInputProps("fachCode")}
      />

      <Textarea
        autosize
        placeholder={`Abholzeit: ab 18:30, Rückgabe ab ...`}
        label="Comments"
        {...form.getInputProps("comments")}
        maxRows={4}
      />

      <Select
        label="Status"
        placeholder="Pick one"
        data={[
          { value: "open", label: "⭕️ Open" },
          { value: "done", label: "✅ Done" },
        ]}
        {...form.getInputProps("status")}
      />

      <Button loading={loading} fullWidth mt={"xs"} type="submit">
        {props.type === "create" && "Create rent"}
        {props.type === "modify" && "Save"}
      </Button>
    </form>
  );
};

export default RentFormComponent;
