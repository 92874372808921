import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import gearReducer from "./gearList/gearSlice";
import authReducer from "./auth/authSlice";
import employeeReducer from "./employees/employeeSlice";

export const store = configureStore({
  reducer: {
    gear: gearReducer,
    auth: authReducer,
    employees: employeeReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
