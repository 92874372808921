import { Autocomplete, Group, Text, Title } from "@mantine/core";
import React, { forwardRef } from "react";
import { useSelector } from "react-redux";
import GearHolderList from "../Gear/GearHolderList.js/GearHolderList";

const QuickSearch = () => {
  const gearListArray = useSelector((state) => state.gear.gearList);

  const data = gearListArray.map((item) => ({ ...item, value: item.name }));

  const AutoCompleteItem = forwardRef(
    ({ name, category, value, currentHolder, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {/* <Avatar src={image} /> */}

          <div>
            <Text>{name}</Text>
            <Text size="xs" color="dimmed">
              {category}
            </Text>
            <GearHolderList item={{ currentHolder, name }} />
          </div>
        </Group>
      </div>
    )
  );

  return (
    <div>
      <Title order={3}>QuickSearch</Title>
      <Autocomplete
        limit={3}
        itemComponent={AutoCompleteItem}
        nothingFound={"No items found"}
        // value={itemsInputValue}
        // onChange={setitemsInputValue}
        placeholder="Pick rent items"
        // data={gearListArray.map(function (obj) {
        //   return `${obj.name} (${obj.category})`;
        // })}
        data={data}
      />
    </div>
  );
};

export default QuickSearch;
