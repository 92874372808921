export const convertTimestampToDate = (timestamp) => {
  //   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

  if (timestamp) {
    return new Date(timestamp.toDate()).toLocaleDateString("de-CH", {
      // weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  }
};

export const convertTimestampToTime = (timestamp) => {
  //   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

  if (timestamp) {
    return new Date(timestamp.toDate()).toLocaleTimeString("de-CH");
  }
};

export const convertTimestampToDateTime = (timestamp) => {
  //   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  if (timestamp) {
    const date = convertTimestampToDate(timestamp);
    const time = convertTimestampToTime(timestamp);

    return `${date} ${time}`;
  }
};

export const convertFirestampTimestampToDateObject = (timestamp) => {
  //   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString

  // console.log(timestamp.toDate());
  if (timestamp) {
    return new Date(timestamp.toDate());
  }
};
