import { useState } from "react";
import { addGearItem, deleteGearItem } from "../../Firestore/firestore";
import GearHolderList from "./GearHolderList.js/GearHolderList";
import { useDispatch, useSelector } from "react-redux";
import { updateGearCategories } from "../../reduxToolkit/gearList/gearSlice";
import { AlertCircle, CircleMinus } from "tabler-icons-react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Divider,
  Group,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import QuickSearch from "../QuickSearch/QuickSearch";
import { convertTimestampToDateTime } from "../../helpers/dateConversions";
import { bull } from "../Rent/RentCard/RentCard";

const Gear = () => {
  const dispatch = useDispatch();

  const [newGear, setnewGear] = useState({ name: "", category: "" });

  const handleAddGear = () => {
    addGearItem(newGear);
    setnewGear({ name: "", category: newGear.category });
  };

  const handleCategoryClick = (selectedCategory) => {
    const newArr = categoriesArray.map((category) => {
      if (category.name === selectedCategory.name) {
        return { ...category, selected: !selectedCategory.selected };
      }
      return category;
    });
    dispatch(updateGearCategories(newArr));
    // console.log(newArr);
  };

  const [checked, setChecked] = useState([]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const gearListArray = useSelector((state) => state.gear.gearList);
  const categoriesArray = useSelector((state) => state.gear.gearCategories);

  return (
    <Card withBorder>
      <h2>Gear List</h2>
      <QuickSearch />
      <Divider my={"xl"} />
      <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
        <Button
          disabled={checked.length === 0}
          color="red"
          variant="outline"
          onClick={() => {
            deleteGearItem(checked);
          }}
        >
          <CircleMinus />
          Delete selected items
        </Button>
      </div>

      {categoriesArray.length > 0 && (
        <>
          <h3>Add gear item</h3>
          <Group align={"end"}>
            <TextInput
              id="gearName"
              value={newGear.name ?? ""}
              onChange={(e) => {
                setnewGear({ ...newGear, name: e.target.value });
              }}
              // size="small"
              label="Item name"
            />

            <Select
              label="Category"
              data={categoriesArray.map((category) => {
                return category.name;
              })}
              onChange={(e) => {
                setnewGear({ ...newGear, category: e });
              }}
              placeholder="Select items"
              nothingFound="Nothing found"
              searchable
              creatable
            />

            <Button
              disabled={newGear.name === "" || newGear.category === ""}
              variant="outline"
              onClick={handleAddGear}
            >
              Add
            </Button>
          </Group>
        </>
      )}

      <br />
      <br />

      <h3>Categories</h3>

      <Group my={"sm"}>
        {categoriesArray.map((category, index) => {
          return (
            <Badge
              sx={(theme) => ({
                "&": {
                  cursor: "pointer",
                },
                "&:hover": {
                  backgroundColor: category.selected
                    ? theme.colors.blue[7]
                    : theme.colors.gray[7],
                  color: "white",
                },
              })}
              key={index}
              onClick={() => {
                handleCategoryClick(category);
              }}
              variant="filled"
              size="md"
              color={category.selected ? "blue" : "gray"}
            >
              {category.name}
            </Badge>
          );
        })}
      </Group>

      {gearListArray.length === 0 && (
        <Alert icon={<AlertCircle size={16} />} title="Error" color="red">
          No items found!
        </Alert>
      )}
      {gearListArray.map((item) => {
        for (let i = 0; i < categoriesArray.length; i++) {
          if (categoriesArray[i].name === item.category) {
            if (!categoriesArray[i].selected) {
              return null;
            }
          }
        }

        return (
          <Paper
            sx={(theme) => ({
              "&:hover": {
                backgroundColor:
                  theme.colorScheme === "light"
                    ? theme.colors.gray[2]
                    : theme.colors.dark[9],
                // color: "white",
              },
            })}
            key={item.name}
            my={2}
            py={3}
            px={"xs"}
            withBorder
          >
            <Group grow>
              <Group>
                <Checkbox
                  onChange={handleToggle(item.name)}
                  checked={checked.indexOf(item.name) !== -1}
                  tabIndex={-1}
                />
                <Stack spacing={0}>
                  <Text size="xs">{item.category}</Text>
                  <Text>{item.name}</Text>
                  <Text size="xs">
                    {item.modifiedLastBy} {bull}{" "}
                    {convertTimestampToDateTime(item.modifiedLastAt)}
                  </Text>
                </Stack>
              </Group>
              <Group grow style={{ justifyContent: "flex-end" }}>
                <GearHolderList item={item} />
              </Group>
            </Group>
          </Paper>
        );
      })}
    </Card>
  );
};

export default Gear;
