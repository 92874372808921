import RentFormComponent from "./RentFormComponent";

const NewRent = () => {
  return (
    <>
      <h2>New rent</h2>
      <RentFormComponent type={"create"} />
    </>
  );
};

export default NewRent;
