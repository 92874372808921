import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyANVZoJKBPMQ-2bIEqse0vdOtYbk3xCGe8",
  authDomain: "visure-inventar.firebaseapp.com",
  projectId: "visure-inventar",
  storageBucket: "visure-inventar.appspot.com",
  messagingSenderId: "1021940623948",
  appId: "1:1021940623948:web:db7e47c081bce34e4e22e9",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Firebase Auth
const firebaseSignOut = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};

export { db, firebaseSignOut };
