import { asyncAddEmployee, deleteEmployee } from "../../Firestore/firestore";
import { AlertCircle, UserCircle, X } from "tabler-icons-react";
import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Card,
  Group,
  Stack,
  TextInput,
} from "@mantine/core";
import { useSelector } from "react-redux";

const Employees = () => {
  const employees = useSelector((state) => state.employees.employeesList);

  const handleAddUser = (e) => {
    e.preventDefault();
    asyncAddEmployee(e.target.firstname.value, e.target.lastname.value);
    e.target.firstname.value = "";
    e.target.lastname.value = "";
  };

  return (
    <Card withBorder>
      <h2>Employees</h2>
      <Stack spacing="xs" align="flex-start">
        {employees?.length <= 0 && (
          <Alert icon={<AlertCircle size={16} />} title="Error" color="red">
            No items found!
          </Alert>
        )}
        {employees.map((employee) => {
          return (
            <Badge
              styles={{
                leftSection: { display: "flex" },
                rightSection: { display: "flex" },
              }}
              leftSection={<UserCircle size={16} />}
              rightSection={
                <ActionIcon
                  size="xs"
                  color="blue"
                  radius="xl"
                  variant="transparent"
                  onClick={async () => {
                    deleteEmployee(employee.id);
                  }}
                >
                  <X size={10} />
                </ActionIcon>
              }
              key={employee.id}
              icon={<UserCircle />}
            >
              {`${employee.firstname} ${employee.lastname}`}
            </Badge>
          );
        })}
      </Stack>
      <h3>Add Employee</h3>
      <form onSubmit={handleAddUser}>
        <Group align={"end"}>
          <TextInput id="firstname" label="Firstname" />
          <TextInput id="lastname" label="Lastname" />
          <Button variant="outline" type="onSubmit">
            Add
          </Button>
        </Group>
      </form>
    </Card>
  );
};

export default Employees;
