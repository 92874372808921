import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeesList: [],
};

export const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    updateEmployeesList: (state, action) => {
      state.employeesList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateEmployeesList } = employeeSlice.actions;

export default employeeSlice.reducer;
