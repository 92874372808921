import { Badge } from "@mantine/core";

const StatusBadge = ({ type, status, variant = "full" }) => {
  const values = {
    [type]: {
      sharely: { color: "teal", text: "Sharely" },
      drehVisure: { color: "orange", text: "Visure Dreh 🎬" },
      mietePrivat: { color: "orange", text: "Privat 🏝" },
      mietePrivatFirma: { color: "orange", text: "Privat 👔" },
    },
  };

  return (
    <Badge
      mx={variant === "minimal" ? 0 : 3}
      styles={{
        leftSection: { display: "flex" },
      }}
      radius="xl"
      color={values[type][status]?.color}
      leftSection={values[type][status]?.icon}
    >
      {values[type][status]?.text}
    </Badge>
  );
};

export default StatusBadge;
