import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  addFeature,
  getAllFeatures,
  deleteFeatures,
} from "../../Firestore/firestore";
import { Button, Card, TextInput } from "@mantine/core";

const FeatureList = () => {
  const [featuresArray, setfeaturesArray] = useState([]);
  const [addNewFeature, setaddNewFeature] = useState("");
  const [selectedFeatureArray, setselectedFeatureArray] = useState([]);

  const columns = [
    // { field: 'Done', headerName: 'Done', width: 90 },
    {
      field: "Feature",
      headerName: "Feature",
      flex: 1,
      editable: false,
    },
  ];

  useEffect(() => {
    loadDataFromFirebase();
  }, []);

  const handleAddFeature = () => {
    addFeature(addNewFeature);
    setaddNewFeature("");
    loadDataFromFirebase();
  };

  const loadDataFromFirebase = async () => {
    setfeaturesArray(await getAllFeatures());
  };

  const removeSelectedFeatures = async () => {
    deleteFeatures(selectedFeatureArray);
    loadDataFromFirebase();
  };

  return (
    <Card withBorder>
      <div style={{ height: 400, width: "100%", marginBottom: 60 }}>
        <h2>Feature List</h2>

        <DataGrid
          rows={featuresArray}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          // disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => {
            setselectedFeatureArray(newSelectionModel);
          }}
          //   selectionModel={selectionModel}
        />
      </div>

      <h3>Add Feature request</h3>

      <TextInput
        id="newFeature"
        value={addNewFeature}
        onChange={(e) => {
          setaddNewFeature(e.target.value);
        }}
        label="New Feature request"
      />

      <Button
        variant="outline"
        onClick={handleAddFeature}
        // disabled={addingInProgress}
      >
        Add
      </Button>

      <Button color="red" variant="outline" onClick={removeSelectedFeatures}>
        Remove selected features
      </Button>
    </Card>
  );
};

export default FeatureList;
