import { deleteRent, setRentStatus } from "../../../Firestore/firestore";
import {
  Badge,
  Box,
  Button,
  Group,
  Modal,
  Paper,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { ArrowBigLeft, Check, InfoCircle, Pencil, X } from "tabler-icons-react";
import { useState } from "react";
import RentFormComponent from "../NewRent/RentFormComponent";
import StatusBadge from "../../helper/StatusBadge";
import { convertTimestampToDate } from "../../../helpers/dateConversions";

export const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function RentCard(props) {
  const [opened, setOpened] = useState(false);
  const [dangerButtonClickedCount, setdangerButtonClickedCount] = useState({
    count: 0,
    text: "",
  });

  const styleBorder = {
    border: "1px solid rgba(200,200,200,0.5)",
    whiteSpace: "pre-wrap",
    fontSize: 14,
    borderRadius: ".4rem",
    padding: ".5rem",
    margin: ".2rem 0",
  };

  return (
    <>
      <Modal
        size="xl"
        centered
        overlayBlur={3}
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Title order={2}>Modify rent</Title>}
      >
        <RentFormComponent type={"modify"} data={props.data} />
      </Modal>

      {/* <Paper my={"xs"}> */}
      <Paper
        // sx={{ ...styleBorder, backgroundColor: "rgba(200,200,200,0.04)" }}
        withBorder
        p={"xs"}
        my={"xs"}
      >
        <Text component={"div"} sx={{ fontSize: 14 }} color="text.secondary">
          <Tooltip
            position="right"
            wrapLines
            label={
              <>
                <span>
                  Created by <strong>{props.data.createdBy}</strong>{" "}
                </span>
                <br />
                <span>
                  Last modified by <strong>{props.data.modifiedLastBy}</strong>
                </span>
              </>
            }
            withArrow
          >
            <InfoCircle size={16} />
          </Tooltip>
          {bull}
          <strong>{props.data.name}</strong> {bull} Fach:{" "}
          <strong>{props.data.fach}</strong> {bull} Code:{" "}
          <strong>{props.data.fachCode}</strong>
          {bull}
          <StatusBadge type="rentType" status={props.data.type} />
          {/* {bull} Status: <strong>{props.data.status}</strong> */}
        </Text>
        <Text component={"div"} sx={{ fontSize: 14 }} color="text.secondary">
          {convertTimestampToDate(props.data.rentDateRange[0])} -{" "}
          {convertTimestampToDate(props.data.rentDateRange[1])}
        </Text>
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          {props.data.items.map((item, index) => {
            return (
              <li key={index}>
                <Badge>{item}</Badge>
              </li>
            );
          })}
        </ul>

        {props.data.contactDetailsRentPerson !== "" && (
          <Text component={"div"} sx={styleBorder} color="text.secondary">
            {props.data.contactDetailsRentPerson}
          </Text>
        )}
        {props.data.comments !== "" && (
          <Text component={"div"} sx={styleBorder} color="text.secondary">
            {props.data.comments}
          </Text>
        )}
        <Group mt={"xs"} noWrap>
          <Button
            disabled={props.data.status === "done"}
            fullWidth
            onClick={async () => {
              // console.log("edit");
              // console.log(props.data);
              setOpened(true);
            }}
            color={"orange"}
          >
            <Pencil />
          </Button>

          <Button
            fullWidth
            onClick={async () => {
              if (props.data.status === "open") {
                await setRentStatus(props.data.id, "done");
              }
              if (props.data.status === "done") {
                await setRentStatus(props.data.id, "open");
              }
            }}
            color={"green"}
          >
            {props.data.status === "open" && <Check />}
            {props.data.status === "done" && <ArrowBigLeft />}
          </Button>
          <Button
            fullWidth
            onClick={async () => {
              let timeout;
              if (dangerButtonClickedCount.count === 0) {
                timeout = setTimeout(() => {
                  setdangerButtonClickedCount({
                    count: 0,
                    text: "",
                  });
                }, 1000);
                setdangerButtonClickedCount({
                  count: 1,
                  text: "DELETE?",
                });
              }
              if (dangerButtonClickedCount.count === 1) {
                clearTimeout(timeout);
                await deleteRent(props.data.id);
              }
            }}
            sx={(theme) => ({
              backgroundColor:
                dangerButtonClickedCount.count === 0
                  ? theme.colors.red[4]
                  : theme.colors.red[8],
              "&:hover": {
                backgroundColor:
                  dangerButtonClickedCount.count === 0
                    ? theme.colors.red[5]
                    : theme.colors.red[9],
              },
            })}
          >
            <X />
            {dangerButtonClickedCount.text}
          </Button>
        </Group>
      </Paper>
      {/* </Paper> */}
    </>
  );
}
