import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gearList: [],
  gearCategories: [],
  rentList: [],
};

export const gearSlice = createSlice({
  name: "gear",
  initialState,
  reducers: {
    updateGearList: (state, action) => {
      state.gearList = action.payload;
    },
    updateGearCategories: (state, action) => {
      state.gearCategories = action.payload;
    },
    updateRentList: (state, action) => {
      // console.log(action.payload);
      state.rentList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateGearList, updateGearCategories, updateRentList } =
  gearSlice.actions;

export default gearSlice.reducer;
