import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

import { db } from "../firebase";

// Firestore CRUD functions
const firestoreAdd = async (
  collectionPath,
  documentName,
  documentDataFields
) => {
  try {
    await setDoc(doc(db, collectionPath, documentName), documentDataFields);
  } catch (err) {
    console.log("Errorr");
    console.log(err);
  }
};

const firestoreAddWithAutoID = async (collectionPath, documentDataFields) => {
  try {
    await addDoc(collection(db, collectionPath), documentDataFields);
  } catch (err) {
    console.log("Errorr");
    console.log(err);
  }
};

const firestoreUpdate = async (
  collectionPath,
  documentName,
  documentDataFields
) => {
  try {
    updateDoc(doc(db, collectionPath, documentName), documentDataFields);
  } catch (err) {
    console.log("Errorr");
    console.log(err);
  }
};

// Employees
const asyncAddEmployee = async (firstname, lastname) => {
  await firestoreAdd("employees", `${firstname}_${lastname}`, {
    firstname,
    lastname,
  });
};

const getEmployeesFromFirestoreDB = async () => {
  try {
    let tmpArray = [];
    const querySnapshot = await getDocs(collection(db, "employees"));
    querySnapshot.forEach((doc) => {
      const tmpEmployee = doc.data();
      tmpArray.push({
        id: doc.id,
        firstname: tmpEmployee.firstname,
        lastname: tmpEmployee.lastname,
      });
    });
    return tmpArray;
  } catch (err) {
    console.log(err);
    return [];
  }
};

// Gear
const addGearItem = async (item) => {
  // console.log(item)
  await firestoreAdd("gear", `${item.name}`, {
    name: item.name,
    category: item.category,
    currentHolder: "none",
  });
};

const getGearItems = async () => {
  try {
    let tmpArray = [];
    const querySnapshot = await getDocs(collection(db, "gear"));
    querySnapshot.forEach((doc) => {
      const tmpGear = doc.data();
      tmpArray.push({
        id: doc.id,
        ...tmpGear,
      });
    });
    return tmpArray;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const updateCurrentHolder = async (
  gearName,
  newHolderName,
  loggedInUserEmail
) => {
  await firestoreUpdate("gear", gearName, {
    currentHolder: newHolderName,
    modifiedLastAt: new Date(),
    modifiedLastBy: loggedInUserEmail,
  });
};

const deleteGearItem = async (dataArray) => {
  dataArray.forEach((item) => {
    return deleteDoc(doc(db, "gear", item));
  });
};

const addFeature = async (name) => {
  try {
    await setDoc(doc(db, "features", `${name}`), {
      name,
    });
  } catch (err) {
    console.log("Errorr");
  }
};

const addCategory = async (name) => {
  try {
    await setDoc(doc(db, "categories", `${name}`), {
      name,
    });
  } catch (err) {
    console.log("Errorr");
  }
};

const getAllFeatures = async () => {
  try {
    let tmpArray = [];
    const querySnapshot = await getDocs(collection(db, "features"));
    querySnapshot.forEach((doc) => {
      const tmp = doc.data();
      tmpArray.push({
        id: doc.id,
        Feature: tmp.name,
      });
    });
    return tmpArray;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllCategories = async () => {
  try {
    let tmpArray = [];
    const querySnapshot = await getDocs(collection(db, "categories"));
    querySnapshot.forEach((doc) => {
      const tmp = doc.data();
      tmpArray.push({
        id: doc.id,
        ...tmp,
        // selected: true
      });
    });
    return tmpArray;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const deleteFeatures = async (array) => {
  array.map((value) => {
    return deleteDoc(doc(db, "features", value));
  });
};

const deleteCategories = async (array) => {
  array.map((value) => {
    return deleteDoc(doc(db, "categories", value));
  });
};

const deleteEmployee = async (id) => {
  return deleteDoc(doc(db, "employees", id));
};

// Rent
const addRent = async (rentData) => {
  await firestoreAddWithAutoID("rents", rentData);
};

const setRentStatus = async (rentId, newStatus) => {
  await firestoreUpdate("rents", rentId, { status: newStatus });
};

const updateRent = async (rentId, newDataObject) => {
  await firestoreUpdate("rents", rentId, newDataObject);
};

const deleteRent = async (id) => {
  return deleteDoc(doc(db, "rents", id));
};

export {
  asyncAddEmployee,
  getEmployeesFromFirestoreDB,
  addGearItem,
  getGearItems,
  addFeature,
  getAllFeatures,
  deleteFeatures,
  updateCurrentHolder,
  deleteGearItem,
  deleteEmployee,
  getAllCategories,
  deleteCategories,
  addCategory,
  addRent,
  deleteRent,
  setRentStatus,
  updateRent,
};
