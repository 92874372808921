import RentCard from "./RentCard/RentCard";
import { useSelector } from "react-redux";

const RentList = ({ title, status }) => {
  const rentList = useSelector((state) => state.gear.rentList);

  const myData = []
    .concat(rentList)
    .filter((rentItem) => rentItem.status === status)
    .sort((a, b) => {
      if (a.status === "done") {
        return b.rentDateRange[0] > a.rentDateRange[1] ? 1 : -1; // Sort by date (most recent on top of the list)
      }
      if (a.status === "open") {
        return a.rentDateRange[0] > b.rentDateRange[1] ? 1 : -1; // Sort by date (most recent on top of the list)
      }
      return null;
    })
    .map((rent) => {
      return <RentCard key={rent.id} data={rent} />;
    });

  return (
    <>
      <h2>{title}</h2>
      {myData}
    </>
  );
};

export default RentList;
