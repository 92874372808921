import { Button, Card, Group, TextInput } from "@mantine/core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  addCategory,
  deleteCategories,
  getAllCategories,
} from "../../Firestore/firestore";

const Categories = () => {
  const [categoriesArray, setcategoriesArray] = useState([]);
  const [selectedCategoriesArray, setselectedCategoriesArray] = useState([]);

  const columns = [
    { field: "name", headerName: "Category Name", flex: 1, editable: false },
  ];

  useEffect(() => {
    loadDataFromFirebase();
  }, []);

  const handleAddCategory = (e) => {
    e.preventDefault();
    // console.log(e.target.categoryName.value)
    addCategory(e.target.categoryName.value);
    e.target.categoryName.value = "";
    loadDataFromFirebase();
  };

  const removeSelectedCategory = async () => {
    deleteCategories(selectedCategoriesArray);
    loadDataFromFirebase();
  };

  const loadDataFromFirebase = async () => {
    setcategoriesArray(await getAllCategories());
  };

  return (
    <Card withBorder>
      <div style={{ height: 400, width: "100%", marginBottom: 60 }}>
        <h2>Categories</h2>

        <DataGrid
          rows={categoriesArray}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setselectedCategoriesArray(newSelectionModel);
          }}
        />
      </div>

      <form onSubmit={handleAddCategory}>
        <Group align={"end"} grow spacing={"xs"}>
          <TextInput
            name="categoryName"
            // size="small"
            label="New Category"
            // variant="outlined"
          />

          <Button variant="outline" type="onSubmit">
            Add
          </Button>
        </Group>
      </form>

      <Group my={"xl"}>
        <Button
          variant="outline"
          color={"red"}
          onClick={removeSelectedCategory}
          disabled={selectedCategoriesArray.length === 0 && true}
        >
          Remove selected categories
        </Button>
      </Group>
    </Card>
  );
};

export default Categories;
