import { Button, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Logout } from "tabler-icons-react";
import { firebaseSignOut } from "../firebase";
import { DarkModeToggle } from "./DarkModeToggle";
import classes from "./Header.module.css";

const Header = (props) => {
  let navigate = useNavigate();

  return (
    <div className={classes.header}>
      <Title ml={"xl"} order={1}>
        VISURE Inventar Tool
      </Title>
      {props.loggedIn && (
        <div className={classes.buttonsLeft}>
          <Button
            color="info"
            variant="outline"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </Button>

          <Button
            color="info"
            variant="outline"
            onClick={() => {
              navigate("/rent");
            }}
          >
            Rent
          </Button>

          <Button
            color="info"
            variant="outline"
            onClick={() => {
              navigate("/admin");
            }}
          >
            Admin
          </Button>

          <Button
            color="red"
            variant="outline"
            onClick={() => {
              firebaseSignOut();
              sessionStorage.removeItem("Auth Token");
            }}
          >
            <Logout />
          </Button>
          <DarkModeToggle />
        </div>
      )}
      {!props.loggedIn && <DarkModeToggle />}
    </div>
  );
};

export default Header;
