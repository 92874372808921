import "./App.css";
import Header from "./Header/Header";
import Employees from "./MainContent/Employees/Employees";
import Gear from "./MainContent/Gear/Gear";
import Login from "./MainContent/Login/Login";
import FeatureList from "./MainContent/FeatureList/FeatureList";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import Categories from "./MainContent/Categories/Categories";
import Rent from "./MainContent/Rent/Rent";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { useDispatch } from "react-redux";
import {
  updateGearCategories,
  updateGearList,
  updateRentList,
} from "./reduxToolkit/gearList/gearSlice";
import { updateEmail } from "./reduxToolkit/auth/authSlice";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { updateEmployeesList } from "./reduxToolkit/employees/employeeSlice";

function App() {
  const [loggedIn, setloggedIn] = useState(false);

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: "mantine-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // const uid = user.uid;
      // console.log("signed in")
      // console.log(user.email);
      dispatch(updateEmail(user.email));
      setloggedIn(true);
      // ...
    } else {
      // console.log("User is signed out")
      setloggedIn(false);
      // User is signed out
      // ...
    }
  });

  const dispatch = useDispatch();
  useEffect(() => {
    let unsubscribeGearList = () => {};
    let unsubscribeCategories = () => {};
    let unsubscribeRentList = () => {};
    let unsubscribeEmployeeList = () => {};

    if (loggedIn) {
      unsubscribeGearList = onSnapshot(
        collection(db, "gear"),
        (querySnapshot) => {
          const gearList = [];
          querySnapshot.forEach((doc) => {
            gearList.push({ ...doc.data(), id: doc.id });
          });
          dispatch(updateGearList(gearList));
        }
      );

      unsubscribeCategories = onSnapshot(
        collection(db, "categories"),
        (querySnapshot) => {
          const categories = [];
          querySnapshot.forEach((doc) => {
            categories.push({ ...doc.data(), id: doc.id });
          });
          dispatch(updateGearCategories(categories));
        }
      );
    }

    unsubscribeRentList = onSnapshot(
      collection(db, "rents"),
      (querySnapshot) => {
        const rentListTmp = [];
        querySnapshot.forEach((doc) => {
          rentListTmp.push({ ...doc.data(), id: doc.id });
        });
        dispatch(updateRentList(rentListTmp));
      }
    );

    unsubscribeEmployeeList = onSnapshot(
      collection(db, "employees"),
      (querySnapshot) => {
        const employeesListTmp = [];
        querySnapshot.forEach((doc) => {
          employeesListTmp.push({ ...doc.data(), id: doc.id });
        });
        dispatch(updateEmployeesList(employeesListTmp));
      }
    );

    return () => {
      unsubscribeGearList();
      unsubscribeCategories();
      unsubscribeRentList();
      unsubscribeEmployeeList();
    };
  }, [dispatch, loggedIn]);

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <div className="App">
          <BrowserRouter>
            <Header loggedIn={loggedIn} />
            <Routes>
              {loggedIn && (
                <>
                  <Route
                    path="/admin"
                    element={
                      <div className="mainGrid">
                        <Categories />
                        <Gear />
                        <Employees />
                        <FeatureList />
                      </div>
                    }
                  />

                  <Route
                    path="/rent"
                    element={
                      <div className="mainGrid">
                        <Rent />
                      </div>
                    }
                  />

                  <Route
                    path="/"
                    element={
                      <div className="mainGrid mainGridGear">
                        <Gear />
                      </div>
                    }
                  />
                </>
              )}

              {!loggedIn && (
                <>
                  {/* <Route exact path="*" element= {<Navigate to="/" />} /> */}

                  <Route
                    path="*"
                    element={
                      <div className="LoginGrid">
                        <Login />
                      </div>
                    }
                  />
                </>
              )}
            </Routes>
          </BrowserRouter>

          {/* <Alert severity='error'>This application is currently under development. Contact info(at)janiswolf.com to learn more ...</Alert> */}
        </div>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
