import { Badge, Group, Loader } from "@mantine/core";
import { useSelector } from "react-redux";
import { updateCurrentHolder } from "../../../Firestore/firestore";

const GearHolderList = (props) => {
  const employeeList = useSelector((state) => state.employees.employeesList);
  const userEmail = useSelector((state) => state.auth.email);

  const changeHolder = async (newHolderName, currentHolder) => {
    // console.log(props.item.name);
    // console.log(newHolderName);
    if (newHolderName === currentHolder) {
      await updateCurrentHolder(props.item.name, "", userEmail);
    } else {
      await updateCurrentHolder(props.item.name, newHolderName, userEmail);
    }
  };

  return (
    <Group spacing="xs">
      {!employeeList && <Loader />}
      {employeeList &&
        employeeList.map((employee) => {
          return (
            <Badge
              sx={(theme) => ({
                "&": {
                  cursor: "pointer",
                },
                "&:hover": {
                  backgroundColor:
                    props.item.currentHolder === employee.id
                      ? theme.colors.blue[7]
                      : theme.colors.gray[7],
                  color: "white",
                },
              })}
              key={employee.id}
              label={`${employee.firstname.charAt(0)}${employee.lastname.charAt(
                0
              )}`}
              size="sm"
              variant="filled"
              color={props.item.currentHolder === employee.id ? "blue" : "gray"}
              onClick={() =>
                changeHolder(employee.id, props.item.currentHolder)
              }
            >
              {`${employee.firstname.charAt(0)}${employee.lastname.charAt(0)}`}
            </Badge>
          );
        })}
    </Group>
  );
};

export default GearHolderList;
